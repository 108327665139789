import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/interface.css'

import onlyLogoHome from './../assets/img/only_logo_home.svg'
import subtitleWhite from './../assets/img/subtitle_white_stroke.svg'
import whiteArrow from './../assets/img/white_arrow.svg'
import interfaceImg from './../assets/img/interface.svg'

import steps from './../assets/img/steps.svg'
import stepsResponsive from './../assets/img/steps_responsive.svg'
import subtitleBlue from './../assets/img/subtitle_blue_stroke.svg'
import whiteStraightStroke from './../assets/img/white_straight_stroke.png'
import paramedicEmblemRight from './../assets/img/paramedic_emblem_right.svg'

const Interface = () => {
  return (
    <>
      <Helmet>
        <title>Interface | Paramedic</title>
      </Helmet>
      <section id='section_interface_home'>
        <div className='container-fluid'>
          <div className='row' id='home-principal-row'>
            <div className='col-lg-6 col-md-9 left_home' data-aos='fade-right' data-aos-duration='1000'>
              <div className='row'>
                <img
                  draggable='false' src={onlyLogoHome} alt='Logo Paramedic Blanc'
                  className='logo_home' data-aos='fade-right' data-aos-duration='1000'
                />
              </div>
              <h1>L'interface</h1>
              <img
                draggable='false' src={subtitleWhite}
                alt='Traits blancs à bouts arrondis' className='subtitle_white_stroke' data-aos='fade-right'
                data-aos-duration='1000' data-aos-delay='500'
              />
              <p data-aos='fade-right' data-aos-duration='1000' data-aos-delay='650'>Paramedic est une plateforme
                web simple et intuitive<br />qui ne nécessite aucune installation. La solution est<br />interfaçable
                avec le DPI de chaque établissement.
              </p>
              <Link to='#section_interface_main'>
                <img
                  draggable='false' src={whiteArrow}
                  alt='Flèche blanche orientée vers le bas'
                  className='white_arrow slide-bottom-faster'
                />
              </Link>
            </div>
            <div className='col-lg-6 right_home' data-aos='fade-left' data-aos-duration='1000'>
              <img
                draggable='false' className='slide-bottom delay_100' src={interfaceImg}
                alt='Illustration groupe de personnes travaillant sur pc'
              />
            </div>
          </div>
        </div>
      </section>

      <section id='section_interface_main'>
        <div className='container-fluid how'>
          <div className='row how-title'>
            <h4 data-aos='fade-left' data-aos-duration='1000'>Comment ça marche ?</h4>
            <img
              draggable='false' src={subtitleBlue} alt='Traits bleus à bouts arrondis'
              className='subtitle-blue-stroke' data-aos='fade-left' data-aos-duration='1000'
            />
            <p data-aos='fade-right' data-aos-duration='1000'>Paramedic réunit autour d’un outil unique l’ensemble
              des acteurs de la chaîne du transport sanitaire. Alors qu’un établissement de santé passait en
              moyenne sept minutes à commander un véhicule, grâce à Paramedic trente secondes suffisent.
            </p>
          </div>
          <div className='row how-main'>
            <div className='col-md-12 how-steps'>
              <img
                draggable='false' className='steps-desktop' src={steps}
                alt='Étapes inscription paramedic'
              />
              <img
                draggable='false' className='steps-mobile' src={stepsResponsive}
                alt='Étapes inscription paramedic'
              />
            </div>
          </div>
        </div>

        <div className='container-fluid features'>
          <div className='row features-title' data-aos='fade-down' data-aos-duration='1000'>
            <h4>Fonctionnalités de l’interface</h4>
            <img
              draggable='false' src={subtitleWhite} alt='Traits blancs à bouts arrondis'
              className='subtitle-white-stroke'
            />
          </div>
          <div className='row features-main'>
            <div className='main-left col-md-8'>
              <div className='row boxes-row'>
                <div className='boxes-container left-boxes col-md-6' data-aos='fade-right' data-aos-duration='1000'>
                  <div className='box'>
                    <h6>Commande simplifiée</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>Grâce à l’interfaçage avec le DPI, l’établissement de santé doit saisir un nombre
                      minime d’informations pour commander un véhicule.
                    </p>
                  </div>
                  <div className='box'>
                    <h6>Suivi du véhicule en temps réel</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>L’utilisateur peut suivre l’avancée de ses transports, de la confirmation du
                      transporteur à l’arrivée à destination.
                    </p>
                  </div>
                  <div className='box'>
                    <h6>Analyse et reporting</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>Paramedic propose un tableau de bord avec des statistiques et analyses personnalisées
                      afin que les dépenses allouées aux transports sanitaires soient gérées au mieux.
                    </p>
                  </div>
                  <div className='box'>
                    <h6>Détection Article 80</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>L’algorithme Paramedic définit la nature du payeur en fonction des informations
                      renseignées lors de la prise de commande.
                    </p>
                  </div>
                </div>
                <div className='boxes-container right-boxes col-md-6' data-aos='fade-left' data-aos-duration='1000'>
                  <div className='box'>
                    <h6>Connexion DPI via API</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>L’interface se synchronise avec le DPI de l’établissement et éviter une ressaisie des
                      informations patients.
                    </p>
                  </div>
                  <div className='box'>
                    <h6>Traçabilité des transports</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>Un historique des commandes permet de consulter les transports par période et/ou par
                      spécificités (nom du patient, société de transports, type de véhicules, etc.
                    </p>
                  </div>
                  <div className='box'>
                    <h6>Assistance téléphonique</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>Une supervision humaine assure le bon déroulement des transports 24h/24 et 7j/7.</p>
                  </div>
                  <div className='box'>
                    <h6>Prix des transports</h6>
                    <img
                      draggable='false' src={whiteStraightStroke}
                      alt='Trait blanc droit' className='straight-stroke'
                    />
                    <p>L’outil Paramedic estime le prix des transports sur la base de l’Assurance Maladie,
                      évitant les incohérences entre les factures émises par les sociétés de transports,
                      et le montant fixé par la CPAM.
                    </p>
                  </div>
                </div>
              </div>
              <Link to='/contact' className='left-button'>Nous contacter</Link>
            </div>
            <div className='main-right col-md-4'>
              <img
                draggable='false' src={paramedicEmblemRight} alt='Emblème Paramedic Blanche'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Interface
